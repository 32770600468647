<template>
  <div class="d-flex flex-wrap px-4">
    <div
      class="col-12 p-0 form--group"
      :class="{ 'has-error': validation.hasError('propertyPrice') }"
      v-if="!project"
    >
      <div class="col-form-label">{{ $t('mortgage.propertyPrice') }}</div>
      <div>
        <cleave
          class="basic--input"
          name="propertyPrice"
          v-model="propertyPrice"
          :options="cleaveOptionsPrice"
        />
        <span class="val-error" v-if="validation.hasError('propertyPrice')">{{
          validation.firstError('propertyPrice')
        }}</span>
      </div>
    </div>
    <div class="col-6 pl-0" v-if="!project">
      <div
        class="form--group"
        :class="{ 'has-error': validation.firstError('selectedPropertyType') }"
      >
        <div class="col-form-label">{{ $t('mortgage.propertyType') }}</div>
        <div>
          <multiselect
            v-model="selectedPropertyType"
            select-label=""
            deselect-label=""
            name="selectedPropertyType"
            track-by="id"
            label="name"
            :searchable="true"
            :close-on-select="true"
            :show-labels="true"
            :options="propertyTypes"
            :placeholder="$t('mortgage.placeholder.propertyType')"
          >
          </multiselect>
          <span class="val-error" v-if="validation.hasError('propertyPrice')">{{
            validation.firstError('selectedPropertyType')
          }}</span>
        </div>
      </div>
    </div>
    <div class="col-6" v-if="!project"></div>
    <div
      class="col-12 p-0 form--group"
      :class="{ 'has-error': validation.hasError('propertyAddress') }"
      v-if="!project"
    >
      <div class="col-form-label">{{ $t('mortgage.propertyAddress') }}</div>
      <div>
        <textarea
          v-model="propertyAddress"
          type="text"
          class="basic--input"
          name="propertyAddress"
          rows="3"
        />
        <span class="val-error" v-if="validation.hasError('propertyAddress')">{{
          validation.firstError('propertyAddress')
        }}</span>
      </div>
    </div>
    <div class="col-12 p-0">
      <div class="form--group" :class="{ 'has-error': validation.hasError('downPayment') }">
        <div class="col-form-label">{{ $t('mortgage.downPayment') }}</div>
        <div>
          <cleave
            class="basic--input"
            name="downPayment"
            v-model="downPayment"
            :options="cleaveOptionsPrice"
          />
          <span class="val-error" v-if="validation.hasError('downPayment')">{{
            validation.firstError('downPayment')
          }}</span>
        </div>
      </div>
    </div>
    <div class="col-12 p-0 mb-4">
      <div class="form--group" :class="{ 'has-error': validation.hasError('tenureInYear') }">
        <div class="col-form-label">{{ $t('mortgage.loanTenure') }}</div>
        <div>
          <input v-model="tenureInYear" type="text" class="basic--input" name="tenureInYear" />
          <span class="val-error" v-if="validation.hasError('tenureInYear')">{{
            validation.firstError('tenureInYear')
          }}</span>
        </div>
      </div>
    </div>
    <div
      class="custom-control custom-checkbox terms--conditions"
      :class="{ 'has-error': validation.hasError('termsAndConditions') }"
    >
      <input
        type="checkbox"
        class="custom-control-input"
        id="termsAndConditions"
        name="termsAndConditions"
        v-model="termsAndConditions"
      />
      <label class="custom-control-label" for="termsAndConditions">
        <span>{{ $t('mortgage.agreement1') }}</span>
        <span
          ><a href="/kebijakan-privasi" target="_blank">{{ $t('mortgage.agreement2') }}</a></span
        >
        <span>{{ $t('mortgage.agreement3') }}</span>
      </label>
    </div>
    <span class="val-error" v-if="validation.hasError('termsAndConditions')">{{
      validation.firstError('termsAndConditions')
    }}</span>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
const Cleave = () => import('vue-cleave-component');
import Multiselect from 'vue-multiselect';
import HelperMixin from '@/mixins/helpers';

require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  props: ['project'],
  mixins: [HelperMixin],
  components: {
    Cleave,
    Multiselect,
  },
  computed: {
    ...mapState({
      ltv: (state) => state.v2.mortgage.ltv,
      propertyPriceValue: (state) => state.v2.mortgage.propertyPrice,
      loanAmountValue: (state) => state.v2.mortgage.loanAmount,
      downPaymentValue: (state) => state.v2.mortgage.downPayment,
      defaultDP: (state) => state.v2.mortgage.defaultDP,
    }),
    ...mapGetters({
      propertyTypes: 'v2/masters/propertyTypesForMortgage',
    }),
    propertyPrice: {
      get() {
        return this.propertyPriceValue;
      },
      set(val) {
        const propertyPriceValue = val
          ? typeof val == 'string'
            ? parseFloat(val.replace('Rp', '').trim())
            : val
          : 0;
        this.$store.commit('v2/mortgage/SET_PROPERTY_PRICE', propertyPriceValue);
      },
    },
    downPayment: {
      get() {
        return this.downPaymentValue;
      },
      set(val) {
        const downPaymentValue = val
          ? typeof val == 'string'
            ? parseFloat(val.replace('Rp', '').trim())
            : val
          : 0;
        this.$store.commit('v2/mortgage/SET_DOWN_PAYMENT', downPaymentValue);
      },
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    propertyPriceValue(val) {
      this.calculateMortgage();
    },
  },
  data() {
    return {
      cleaveOptionsPrice: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        prefix: 'Rp ',
      },
      selectedPropertyType: null,
      propertyAddress: null,
      tenureInYear: '20',
      termsAndConditions: false,
      firstCalculation: false,
    };
  },
  validators: {
    // eslint-disable-next-line no-unused-vars
    propertyPrice(value) {
      if (this.project) {
        return Validator.value(value);
      } else {
        return Validator.value(this.propertyPriceValue)
          .required(this.$i18n.t('errors.propertyPrice.required'))
          .float(this.$i18n.t('errors.propertyPrice.number'))
          .greaterThan(0, this.$i18n.t('errors.propertyPrice.greaterThan'));
      }
    },
    selectedPropertyType(value) {
      if (this.project) {
        return Validator.value(value);
      } else {
        return Validator.value(value).required(this.$i18n.t('errors.propertyType.required'));
      }
    },
    propertyAddress(value) {
      if (this.project) {
        return Validator.value(value);
      } else {
        return Validator.value(value).required(this.$i18n.t('errors.propertyAddress.required'));
      }
    },
    // eslint-disable-next-line no-unused-vars
    downPayment(value) {
      let validator = Validator.value(this.downPaymentValue)
        .required(this.$i18n.t('errors.downPayment.required'))
        .float(this.$i18n.t('errors.downPayment.number'));
      // let validator = Validator.value(this.loanAmountValue)
      //   .required(this.$i18n.t('errors.loanAmount.required'))
      //   .float(this.$i18n.t('errors.loanAmount.number'));
      // if (this.project && !this.project.view_to_offer) {
      //   let max = this.project.sell_price_raw * this.ltv;
      //   validator = validator
      //     .greaterThan(0, this.$i18n.t('errors.loanAmount.greaterThan'))
      //     .lessThanOrEqualTo(
      //       max,
      //       this.$i18n.t('errors.loanAmount.lessThanOrEqualTo', { max: max }),
      //     );
      // }
      return validator;
    },
    tenureInYear(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.tenureInYear.required'))
        .float(this.$i18n.t('errors.tenureInYear.number'))
        .greaterThanOrEqualTo(1, this.$i18n.t('errors.tenureInYear.greaterThanOrEqualTo'))
        .lessThanOrEqualTo(30, this.$i18n.t('errors.tenureInYear.lessThanOrEqualTo'));
    },
    termsAndConditions(value) {
      return Validator.value(value).in([true], this.$i18n.t('errors.termsAndConditions.required'));
    },
  },
  mounted() {
    this.loadQuery();
  },
  methods: {
    validate: function () {
      this.$store.commit('v2/mortgage/SET_SELECTED_PROPERTY_TYPE', this.selectedPropertyType);
      this.$store.commit('v2/mortgage/SET_PROPERTY_ADDRESS', this.cleanInput(this.propertyAddress));
      this.$store.commit('v2/mortgage/SET_TENURE_IN_YEAR', this.tenureInYear);
      this.$store.commit('v2/mortgage/SET_TERMS_CONDITIONS', this.termsAndConditions);
      return this.$validate();
    },
    calculateMortgage() {
      console.log('CALCULATE MORTGAGE: ', this.propertyPriceValue);
      this.downPayment = this.propertyPriceValue * this.defaultDP;
      if (!this.firstCalculation) {
        if (this.propertyPriceValue && !isNaN(this.propertyPriceValue))
          this.firstCalculation = true;
        this.loadQuery();
      }
    },
    loadQuery() {
      let tenure = this.$route.query.tenure_in_year;
      if (tenure) {
        this.tenureInYear = tenure;
      }

      let dp = this.$route.query.down_payment;
      if (dp) {
        this.downPayment = parseInt(dp);
      }

      let propertyPrice = this.$route.query.property_price;
      if (propertyPrice) {
        this.propertyPrice = parseInt(propertyPrice);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.form--group {
  display: block;
  .val-error {
    margin-top: 8px;
  }
}
.terms--conditions {
  font-size: $font-sm;
}
</style>
